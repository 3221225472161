import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Avatar,
  Collapse,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { customColors } from '../../../helpers/theme';
import Logo from '../../../public/images/logo.svg';
import AvatarIcon from '../../../public/images/avataricon.svg';
import LogoutIcon from '../../../public/images/logouticon.svg';
import { BROWSE_COLLECTIONS_ROUTE, PROFILE_ROUTE } from 'config/routes';

export const Header = () => {
  const router = useRouter();
  const { isOpen, onToggle } = useDisclosure();
  const handleProfile = () => {
    router.push(PROFILE_ROUTE);
  };

  return (
    <Box as="header">
      <Flex minH={'60px'} py={{ base: 2 }} px={{ base: 6 }} align={'center'}>
        <Flex ml={{ base: '-2' }} display={{ base: 'flex', md: 'none' }}>
          <IconButton
            id="hamburgericon"
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          alignItems={'center'}
          justify={{ base: 'center', md: 'start' }}>
          <Link href={BROWSE_COLLECTIONS_ROUTE}>
            <Image src={Logo} alt="logo" />
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} m={'auto'}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Flex alignItems={'center'}>
          <Menu id="userdropdown">
            <MenuButton rounded={'full'} cursor={'pointer'}>
              <Avatar size={'md'} src={AvatarIcon.src} />
            </MenuButton>
            <MenuList
              border={0}
              boxShadow={'0px 4px 28px rgba(0, 0, 0, 0.12)'}
              borderRadius={'sm'}
              minW={'298px'}>
              <MenuItem onClick={handleProfile} color={customColors.black}>
                Profile Settings
              </MenuItem>
              <hr className="menudivider" />
              <MenuItem
                color={customColors.black}
                as="a"
                href="/api/auth/logout">
                <Image src={LogoutIcon} alt="LogoutIcon" />
                <span className="ml-10">Log out</span>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};
