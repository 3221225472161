import { BROWSE_COLLECTIONS_ROUTE } from 'config/routes';
import { NavItem } from '../../../interfaces';
export const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Browse collections',
    href: BROWSE_COLLECTIONS_ROUTE,
  },
  {
    label: 'My Portfolio',
    href: '/portfolio',
  },
  {
    label: 'Contact us',
    href: 'https://www.nval.com/contact',
    target: '_blank',
  },
];
