import React, { useState, useEffect } from 'react';
import { Header } from '../components/common/header';
import Footer from '../components/common/footer';
import {
  Button,
  Heading,
  VStack,
  Text,
  Box,
  Container,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { useUser } from 'store/User/UserContext';
import { BROWSE_COLLECTIONS_ROUTE } from '../config/routes';

const Custom404 = ({ error }) => {
  const { push } = useRouter();
  const [resetPage, setResetPage] = useState('');
  useEffect(() => {
    if (resetPage) {
      push(resetPage);
    }
  }, [push, resetPage]);

  const { userstate } = useUser();
  const resetClick = () => {
    if (userstate?.currentUser?.email) {
      setResetPage(BROWSE_COLLECTIONS_ROUTE);
    } else {
      setResetPage('/');
    }
  };
  return (
    <>
      <Header />
      <Box as="section" className="innerpages" pt={24}>
        <Container maxW="container.xl">
          <VStack align="center" py={10}>
            <Heading pb={4} maxW={500} textAlign="center">
              Woops! Looks like that page doesn&apos;t exist
            </Heading>
            <Text mb={2} maxW={500} align="center">
              Sorry about that. Seems like something isn&apos;t quite right. For
              now, we recommend refreshing the page or coming back later.
            </Text>
            <Text mb={1} maxW={500} align="center">
              {error && error.message}
            </Text>
            <Button variant="secondary" onClick={() => resetClick()}>
              Click here to retun to the homepage!
            </Button>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Custom404;
