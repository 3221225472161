import { Flex, Text, Stack, Link } from '@chakra-ui/react';
import { customColors } from '../../../helpers/theme';
import { useRouter } from 'next/router';
import { NAV_ITEMS } from './NavItems';
import { NavItem } from '../../../interfaces';

const MobileNavItem = ({ label, href }: NavItem) => {
  const router = useRouter();
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={
            router.pathname == href ? customColors.red : customColors.dgrey
          }>
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};

export const MobileNav = () => {
  return (
    <Stack p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};
