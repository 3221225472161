import { Box, Container, Text, Flex, Link } from '@chakra-ui/react';
import moment from 'moment';
import NextLink from 'next/link';
const year = moment.utc(Date.now()).format('YYYY');

const Footer = () => {
  return (
    <Box as="footer" className="footer" mt="108px" mb="24px">
      <Container maxW="container.xl">
        <Flex justifyContent={['center', 'space-between']} wrap="wrap">
          <Box>
            <Text color="gray.500">©{year} NVAL. All rights reserved.</Text>
          </Box>
          <Box>
            <NextLink href="https://www.nval.com/privacy" passHref>
              <Link id="footerlink1" color="gray.500" isExternal ml={'32px'}>
                Privacy Policy
              </Link>
            </NextLink>
            <NextLink href="https://www.nval.com/TOS" passHref>
              <Link id="footerlink2" color="gray.500" isExternal ml={'32px'}>
                Terms & Conditions
              </Link>
            </NextLink>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
