import { Box, Stack, Link, useColorModeValue } from '@chakra-ui/react';
import { customColors } from '../../../helpers/theme';
import { NAV_ITEMS } from './NavItems';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

export const DesktopNav = () => {
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const router = useRouter();
  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <NextLink href={navItem.href ?? '#'} passHref>
            <Link
              id={navItem.label}
              p={2}
              color={
                router.pathname == navItem.href
                  ? customColors.red
                  : customColors.dgrey
              }
              isExternal
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}>
              {navItem.label}
            </Link>
          </NextLink>
        </Box>
      ))}
    </Stack>
  );
};
